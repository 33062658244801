
<template>
  <ValidationObserver
    ref="creditTransferForm"
    v-slot="{ handleSubmit }"
  >
    <form @submit.prevent="handleSubmit(onSubmit)">
      <b-form-group>
        <b-form-radio-group
          v-model="transactionType"
          button-variant="light"
          buttons
        >
          <b-form-radio
            value="deposit"
            :button-variant="transactionType=== 'deposit'?'outline-success': ''"
          >
            {{ $t('credits.deposit')}}
          </b-form-radio>
          <b-form-radio
            value="withdraw"
            :button-variant="transactionType=== 'withdraw'?'outline-danger': ''"
          >
            {{ $t('credits.withdraw')}}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <ValidationProvider
        v-slot="{ errors }"
        :name="$t('credits.credit_amount')"
        rules="required|min_value:1"
      >
        <b-form-group
          label-cols-sm="12"
          label-cols-lg="3"
          :label="`${$t('credits.credit_amount')} *`"
          label-for="creditAmount"
        >
          <b-form-input
            id="creditAmount"
            v-model="transferForm.creditAmount"
            type="number"
            :state="errors[0] ? false : null"
          ></b-form-input>
        </b-form-group>
      </ValidationProvider>

      <b-form-group
        label-cols-sm="12"
        label-cols-lg="3"
        :label="$t('fields.remark')"
        label-for="remark"
      >
        <b-form-textarea
          id="remark"
          v-model="transferForm.remark"
        ></b-form-textarea>
      </b-form-group>
      <div class="text-right">
        <b-button
          variant="light"
          class="mr-2"
          @click="$emit('close')"
        >
          {{ $t('buttons.cancel')}}
        </b-button>
        <b-overlay
          :show="loading"
          rounded
          spinner-small
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="primary"
            block
          >
            {{ $t('buttons.confirm')}}
          </b-button>
        </b-overlay>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  data() {
    return {
      transactionType: 'deposit',
      transferForm: {
        creditAmount: 0,
        remark: '',
      },
    }
  },
  computed: {
    loading() {
      return false
    },
  },
  methods: {
    onSubmit() {},
  },
}
</script>
